<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    xml:space="preserve"
    width="24"
    height="24"
    fill="currentColor"
  >
    <path
      d="M6.9,24c-0.4,0-0.9-0.2-1.2-0.5c-0.7-0.7-0.7-1.8,0-2.4l9.1-9.1L5.6,2.9C5,2.3,5,1.2,5.6,0.5s1.8-0.7,2.4,0l10.3,10.3c0.7,0.7,0.7,1.8,0,2.4L8.1,23.5C7.7,23.8,7.3,24,6.9,24z"
    />
  </svg>
</template>
